/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-10",
    versionChannel: "nightly",
    buildDate: "2024-10-10T00:06:08.482Z",
    commitHash: "66a909d539470e7586e1f5ca5ef22e441cead9ad",
};
